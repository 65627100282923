.post {
  @include grid-container;
  .post-meta {
    margin-left: 0;
    display: block;
  }

  .post-excerpt {
    @include grid-column(9);
    margin-left: 0;
  }

  .post-image-container {
    @include grid-column(3);
    display: inline-block;
    height: 150px;

    .post-image {
      display: block;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .pager {
    text-align: center;
    list-style: none;

    li {
      display: inline;
    }
  }

  @include media-query($on-palm) {
    .post-excerpt {
      @include grid-column(12);
      margin-left: 0;
    }

    .post-image-container {
      @include grid-column(12);
      margin-left: 0;
    }
  }
}

.page-navigation {
  .pager {
    text-align: center;
    list-style: none;

    li {
      display: inline;
    }
  }
}

.rss-subscribe {
  @include grid-column(12);
  margin-left: 0;
}

.post-content {
  .post-banner-image > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 250px;
  }
}

.twitter-plug-container {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.list-2-column {
  columns: 2;
  list-style-position: inside;
}

.search {
  form {
    text-align: center;
  }
  input[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    display: block;
  }
  input[type="submit"] {
    background-color: #555555;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    width: 50%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  label {
    margin: 30px 0 10px 0;
    display: block;
  }
  .search-results {
    padding-top: 10px;

    li {
      list-style-type: none;
    }
  }
}

.search-link-icon {
  @include media-query($on-palm) {
    display: none;
  }
}

.search-link-text {
  display: none;
  @include media-query($on-palm) {
    display: inline-block;
  }
}
